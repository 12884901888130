import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { MTLLoader, OBJLoader } from "three-obj-mtl-loader";

declare var $: any;

declare function require(name: string);
var THREE = require("three");

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  name = "";
  camera;
  scene;
  scene1;
  scene2;
  renderer;
  renderer1;
  renderer2;

  geometry;
  mesh = null;
  light;
  light1;
  light2;
  pointLight;
  loader = new OBJLoader();
  loader1;
  loader2;
  mtlLoader = new MTLLoader();

  renaultLogo = null;
  volvoLogo = null;
  volvoLogo1 = null;
  mercedesLogo = null;
  mesh1 = null;
  angle = 0.01;
  container;
  container1;
  container2;

  mercedesGroupLogo = new THREE.Group();

  element;

  scenes = [];

  toggy() {
    let aa = document.getElementById("startcount").getAttribute("endVal");

    return aa;
  }

  toggy2() {
    let bb = document.getElementById("startcount").getAttribute("endValSS");
    return bb;
  }

  ngOnInit() {
    this.animate = this.animate.bind(this);
    this.init();

    this.modelLoading();
    this.modelLoading1();
    this.modelLoading3();
    this.modelLoading2();

    this.resize();

    window.addEventListener("resize", event => this.resize());
    this.animate();

    var offtop = 58;

    $(".navbar .be a").click(function () {
      $("body,html").animate(
        {
          scrollTop: $("#" + $(this).data("value")).offset().top
        },
        1000
      );
    });

    $(".navbar a.be1").click(function () {
      $("body,html").animate(
        {
          scrollTop: $("#" + $(this).data("value")).offset().top - offtop
        },
        1000
      );
    });

    $(document).ready(function () {
      $(".header").height($(window).height());
    });

    // Start Count

    // var element_position1 = $("#startcount").offset().top;
    // var screen_height = $(window).height();
    // var activation_offset = 0.5;
    // var activation_point1 = element_position1 - 1000;
    // var max_scroll_height = $("body").height() - screen_height - 5;

    // $(window).on("scroll", function() {
    //   var y_scroll_pos = window.pageYOffset;

    //   var element_in_view1 =
    //     y_scroll_pos > activation_point1 &&
    //     y_scroll_pos < activation_point1 + 1500;

    //   if (element_in_view1) {
    //     document.getElementById("startcount").removeAttribute("endValSS");
    //     document.getElementById("startcount").setAttribute("endVal", "5000");
    //   } else {
    //     document.getElementById("startcount").removeAttribute("endVal");
    //     document.getElementById("startcount").setAttribute("endValSS", "5000");
    //   }
    // });

    // End Count
  }

  resize() {
    // var w = this.container.offsetWidth;
    // var h = this.container.offsetHeight;

    var w1 = this.container1.offsetWidth;
    var h1 = this.container1.offsetHeight;

    var w2 = this.container2.offsetWidth;
    var h2 = this.container2.offsetHeight;

    // this.renderer.setSize(w, h);
    this.renderer1.setSize(w1, h1);
    this.renderer2.setSize(w2, h2);

    // this.camera.aspect = w / h;
    this.camera.updateProjectionMatrix();
  }

  init() {
    this.camera = new THREE.PerspectiveCamera(
      70,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    this.camera.position.z = 50;

    this.scene = new THREE.Scene();
    this.scene1 = new THREE.Scene();
    this.scene2 = new THREE.Scene();

    this.light = new THREE.PointLight(0xffffff);
    this.light.position.set(-10, 0, 30);

    this.light1 = new THREE.PointLight(0xffffff);
    this.light1.position.set(-10, 0, 30);

    this.light2 = new THREE.PointLight(0xffffff);
    this.light2.position.set(-10, 0, 30);

    this.scene.add(this.light);
    this.scene1.add(this.light1);
    this.scene2.add(this.light2);

    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    this.renderer1 = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    this.renderer2 = new THREE.WebGLRenderer({ antialias: true, alpha: true });

    // this.container = document.getElementById("canvas-container");
    this.container1 = document.getElementById("canvas-container1");
    this.container2 = document.getElementById("canvas-container2");

    // this.container.appendChild(this.renderer.domElement);
    this.container1.appendChild(this.renderer1.domElement);
    this.container2.appendChild(this.renderer2.domElement);
  }

  modelLoading() {
    var context = this;

    this.mtlLoader.load("../assets/Renault logo.mtl", function (materials) {
      materials.preload();

      context.loader
        .setMaterials(materials)
        .load("../assets/Renault logo.obj", function (object) {
          context.renaultLogo = object.children[0];
          context.renaultLogo.rotateX(Math.PI / 2);
          context.renaultLogo.scale.set(6, 6, 6);

          var center = new THREE.Vector3();
          context.renaultLogo.geometry.computeBoundingBox();
          context.renaultLogo.geometry.boundingBox.getCenter(center);
          context.renaultLogo.geometry.center();

          context.scene.add(context.renaultLogo);
        });
    });
  }

  modelLoading1() {
    // var context = this;

    // this.mtlLoader.load("../assets/VolvoLogoC1.mtl", function (materials) {
    //   materials.preload();

    //   // context.loader
    //   new OBJLoader()
    //     .setMaterials(materials)
    //     .load("../assets/VolvoLogoC1.obj", function (object) {
    //       context.volvoLogo = object.children[0];
    //       context.volvoLogo.rotateY(Math.PI);
    //       context.volvoLogo.scale.set(6, 6, 6);

    //       var center = new THREE.Vector3();
    //       context.volvoLogo.geometry.computeBoundingBox();
    //       context.volvoLogo.geometry.boundingBox.getCenter(center);
    //       context.volvoLogo.geometry.center();

    //       context.scene1.add(context.volvoLogo);
    //     });
    // });
  }

  modelLoading3() {
    // var context = this;

    // this.mtlLoader.load("../assets/VolvoLogoC1.mtl", function (materials1) {
    //   materials1.preload();

    //   context.loader
    //     .setMaterials(materials1)
    //     .load("../assets/VolvoLogoC1.obj", function (object1) {
    //       context.volvoLogo1 = object1.children[0];
    //       context.volvoLogo1.rotateY(Math.PI);
    //       context.volvoLogo1.scale.set(6, 6, -6);

    //       var center = new THREE.Vector3();
    //       context.volvoLogo1.geometry.computeBoundingBox();
    //       context.volvoLogo1.geometry.boundingBox.getCenter(center);
    //       context.volvoLogo1.geometry.center();
    //       context.volvoLogo1.position.z += 1.1;

    //       context.scene1.add(context.volvoLogo1);
    //     });
    // });
  }

  modelLoading2() {
    var context = this;

    this.mtlLoader.load("../assets/OBJ.mtl", function (materials) {
      materials.preload();

      context.loader
        .setMaterials(materials)
        .load("../assets/OBJ.obj", function (object) {
          context.mercedesLogo = object;
          context.mesh = context.mercedesLogo.children[0];
          context.mesh1 = context.mercedesLogo.children[1];

          context.mesh.material.color = { r: 0.64, g: 0.64, b: 0.64 };
          context.mesh1.material = context.mesh.material;

          context.mercedesGroupLogo.add(context.mesh);
          context.mercedesGroupLogo.add(context.mesh1);

          var center = new THREE.Vector3();
          context.mesh.geometry.computeBoundingBox();
          context.mesh.geometry.boundingBox.getCenter(center);
          context.mesh.geometry.center();

          context.mesh1.geometry.computeBoundingBox();
          context.mesh1.geometry.boundingBox.getCenter(center);
          context.mesh1.geometry.center();

          context.mesh1.position.y += 23;
          context.mesh1.position.z += 3;

          context.mercedesGroupLogo.scale.set(0.15, 0.15, 0.15);

          context.scene2.add(context.mercedesGroupLogo);
        });
    });
  }

  animate() {
    if (this.renaultLogo !== null) {
      this.renaultLogo.rotation.z = this.angle;
    }

    // if (this.volvoLogo !== null) {
    //   this.volvoLogo.rotation.y = this.angle;
    // }

    // if (this.volvoLogo1 !== null) {
    //   this.volvoLogo1.rotation.y = this.angle;
    // }

    if (this.mesh1 !== null) {
      this.mercedesGroupLogo.rotation.y = this.angle * -1;
    }

    this.angle += 0.015;

    requestAnimationFrame(this.animate);

    // this.renderer.render(this.scene1, this.camera);
    this.renderer1.render(this.scene, this.camera);
    this.renderer2.render(this.scene2, this.camera);
  }

  title = "velmos";
}
